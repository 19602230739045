.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin-top: 10px;
}

.menu-items {
    display: flex;
    align-items: center;
    justify-content: center;
}

.website-language {
    position: absolute;
    right: 30px;
}

.not-active-button, .active-button {
    padding: 4px 8px;
    font-size: 1rem;
    font-weight: bold;
    border: 0;
    cursor: pointer;
    border-radius: 6px;
}

.not-active-button + .active-button, .active-button + .not-active-button {
    margin-right: 5px;
}

.not-active-button {
    background: transparent;
}

.active-button {
    background: var(--color3);;
}

.nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: none;
}

.menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-out;
    width: 90px;
    height: 90px;
    background: var(--color3);
    border-radius: 100%;
    cursor: pointer;
}

.menu-item + .menu-item {
    margin-left: 60px;
}

.menu-item-img {
    display: flex;
    color: var(--color1);
    width: 2.5rem;
    height: 2.5rem;
}

.menu-item-text {
    display: flex;
    font-weight: bold;
    font-size: 0.9rem;
    color: #000;
}

.menu-item:hover {
    transition: all 0.5s ease-out;
    transform: scale(1.2);
    color: var(--color1);
}

@media screen and (max-width: 480px) {
    .menu-item {
        width: 65px;
        height: 65px;
    }

    .menu-item + .menu-item {
        margin-left: 25px;
    }

    .menu-item-img {
        width: 2rem;
        height: 2rem;
    }

    .menu-item-text {
        font-size: 0.65rem;
    }

    
}

@media screen and (max-width: 768px) {
    .website-language {
        display: flex;
        flex-direction: column;
        right: 5px;
    }

    .not-active-button, .active-button {
        padding: 2px 4px;
        font-size: 0.9rem;
    }
}