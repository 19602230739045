@import url("https://fonts.googleapis.com/css?family=Comfortaa:400,500,700");
@import "./index.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Comfortaa';
}

body {
    background: var(--color1);
}