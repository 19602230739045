:root {
  --color1: #fefae0;
  --color2: #5cd5ae;
  --color3: #d4a373;
}

@keyframes showComponent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
