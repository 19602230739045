.projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-item {
    background: #daf7dc;
    position: relative;
    display: flex;
    align-items: center;
    visibility: hidden;
    animation: showComponent 2.5s 1s forwards;
    width: 80%;
    border: 3px solid #000;
    border-radius: 50px;
    margin-top: 30px;
    overflow: hidden;
}

.project-wrapper {
    display: flex;
    transition: all 0.5s ease-out;
    cursor: pointer;
}

.project-wrapper:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-out;
}

.projects-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
}

.project-one {
    display: flex;
    width: 80%;
    border-radius: 50px;
    box-shadow: 0 0 5px 1px var(--color2);
    margin-bottom: 10px;
}

.project-description {
    display: flex;
    font-weight: bold;
    font-size: 1.3rem;
    margin: 10px;
}

.link-button {
    display: flex;
    position: absolute;
    font-weight: bold;
    top: 30px;
    justify-content: center;
    align-items: center;
    right: 30px;
    width: 50px;
    height: 50px;
    border: 3px solid #000;
    border-radius: 50px;
    background: var(--color3);
}

.link-image {
    font-size: 30px;
}

@media screen and (max-width: 480px) {
    .projects-item {
        width: 80%;
    }

    .project-description {
        text-align: center;
        font-size: 1rem;
    }

    .link-button {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 10px;
    }

    .link-image {
        font-size: 10px;
    }

    .project-one {
        width: 90%;
    }
}

@media screen and (max-width: 992px) {
    .project-description {
        text-align: center;
        font-size: 1rem;
    }

    .link-button {
        width: 35px;
        height: 35px;
        top: 20px;
        right: 15px;
    }

    .link-image {
        font-size: 20px;
    }

    .project-one {
        width: 90%;
    }
}